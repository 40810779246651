import styled from 'styled-components'

export const Header = styled.header`
  background-color: var(--gray100);
  padding: 24px 100px;

  @media (min-width: 1440px) {
    padding: 24px 156px;
  }

  @media (max-width: 1023px) {
    padding: 11px 24px;
  }
`
