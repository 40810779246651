import React from 'react'

import Stepper from '@/components/Stepper'
import EstateKey from '@/assets/estate_key.png'
import useBreakpoint from '@/hooks/useBreakpoint'
import { breakpoints } from '@/utils/constants/breakpoints'

import * as S from './styles'
import { FormContainerProps } from './types'

const FormContainer = ({ children }: FormContainerProps) => {
  const { breakpoint } = useBreakpoint()
  const isLargeScreen = [breakpoints.LG].includes(breakpoint)

  return (
    <S.Container>
      {isLargeScreen && (
        <S.LeftBox>
          <S.Figure>
            <img src={EstateKey} alt="estate keys on hands" width={304} />
            <S.PageTitle variant="headline-big" semiBold>
              Formulário de dados do imóvel
            </S.PageTitle>
          </S.Figure>
        </S.LeftBox>
      )}
      <S.RightBox>
        <Stepper />
        {children}
      </S.RightBox>
    </S.Container>
  )
}

export default FormContainer
