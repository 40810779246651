import styled from 'styled-components'

import { Text } from '@inter/inter-ui/components/Text'

export const Container = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex: 1 1 0;
`

export const LeftBox = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`

export const RightBox = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`

export const Figure = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const PageTitle = styled(Text)`
  max-width: 350px;
  margin-top: 40px;
`
