import React, { useContext, useEffect } from 'react'

import { useNavigate, useParams } from 'react-router-dom'

import routes from '@/routes/RoutesNames'
import { getAttempts } from '@/services/datazap'
import { useHandleErrorNotification } from '@/utils/handleErrorNotification'
import {
  CondominiumConditionType,
  EstateConditionType,
  EstateContext,
  EstateFeaturesType,
  EstateLocationType,
  FinishingStandardType,
} from '@/hooks/useEstateContext'


const Home = () => {
  const navigate = useNavigate()
  const { handleErrorNotification } = useHandleErrorNotification()
  const { codigoScci } = useParams()
  const {
    setCodigoScci,
    setEstateLocation,
    setEstateFeatures,
    setEstateCondition,
    setFinishingStandard,
    setCondominiumCondition,
    setStep,
    setIsEditing,
  } = useContext(EstateContext)


  useEffect(() => {
    codigoScci && setCodigoScci(codigoScci) // TODO: quando a autenticação vier da staging para a master, esta lógica vai para o componente PrivateRoute e o codigoScci usado aqui será o do context
    const fetchAttempts = async () => {
      try {
        if (codigoScci) {
          const data = await getAttempts(codigoScci)
          const attempts = data.quantidade
          const isValid = data.valido

          setEstateLocation({} as EstateLocationType)
          setEstateFeatures({} as EstateFeaturesType)
          setEstateCondition('' as EstateConditionType)
          setFinishingStandard('' as FinishingStandardType)
          setCondominiumCondition('' as CondominiumConditionType)
          setStep(1)
          setIsEditing(false)

          if (attempts === 0) {
            navigate(`/${routes.FORM_LOCATION}`)
          } else if (isValid) {
            navigate(`/${routes.FILL_AGAIN}`)
          } else {
            navigate(`/${routes.LIMIT_REACHED}`)
          }
        }
      } catch {
        handleErrorNotification()
      }
    }
    fetchAttempts()
  }, [navigate])

  return <></>
}

export default Home
