import {
  CondominiumConditionType,
  EstateConditionType,
  EstateFeaturesType,
  EstateLocationType,
  FinishingStandardType,
} from '@/hooks/useEstateContext'

import { removeMask } from './Masks'

export type FormatPayloadType = {
  codigoScci: string
  estateLocation: EstateLocationType
  estateFeatures: EstateFeaturesType
  estateCondition: EstateConditionType
  finishingStandard: FinishingStandardType
  condominiumCondition: CondominiumConditionType
}
export const formatPayload = ({
  codigoScci,
  estateLocation,
  estateFeatures,
  estateCondition,
  finishingStandard,
  condominiumCondition,
}: FormatPayloadType) => {
  const { zipCode, street, number, complement, district, state, city } = estateLocation
  const {
    estateType,
    area,
    dorms,
    bathrooms,
    suites,
    garages,
    buildingYear,
    propertyCondition,
    additionalData,
  } = estateFeatures
  return {
    codigoScci: codigoScci,
    imovel: {
      cep: Number(removeMask(zipCode)),
      endereco: street,
      numero: Number(number),
      complemento: complement,
      bairro: district,
      cidade: city,
      estado: state,
      tipo: estateType,
      areaUtil: Number(area),
      quantidadeDormitorios: Number(dorms),
      quantidadeBanheiros: Number(bathrooms),
      quantidadeSuites: Number(suites),
      quantidadeVagasGaragem: Number(garages),
      anoConstrucao: Number(buildingYear),
      imovelEmReforma: propertyCondition,
      infosComplementares: additionalData,
      acabamento: finishingStandard,
      conservacao: estateCondition,
      conservacaoCondominioPredio: condominiumCondition,
    },
  }
}
