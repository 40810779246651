import React from 'react'

import EstateFeatures from '@/components/EstateForm/EstateFeatures'
import FormContainer from '@/components/FormContainer'

const EstateFeaturesPage = () => (
  <FormContainer>
    <EstateFeatures />
  </FormContainer>
)

export default EstateFeaturesPage
