import styled from 'styled-components'

import { Text } from '@inter/inter-ui/components/Text'
import { Flex } from '@inter/inter-ui/components/Flex'
import { Spacing } from '@inter/inter-ui/components/Spacing'

export const CheckboxContainer = styled.div`
  max-width: 450px;
`

export const DarkText = styled(Text)`
  color: var(--typography500);
  font-weight: 700;
  word-break: break-word;
`

export const FullWidthFlex = styled(Flex)`
  @media (max-width: 1023px) {
    justify-content: space-between;
    width: 100%;
  }
`

export const ColumnSpacing = styled(Spacing)`
  flex: 1 1 0px;

  @media (max-width: 1023px) {
    margin-right: 0px;
    width: 100%;
  }
`
