export default {
  HOME: '/',
  FORM_LOCATION: 'localizacao',
  FORM_FEATURES: 'caracteristicas',
  FORM_CONDITION: 'conservacao',
  FORM_FINISHING_STANDARD: 'padrao-acabamento',
  FORM_CONDOMINIUM: 'conservacao-condominio',
  FORM_REVIEW: 'conferir-informacoes',
  FILL_AGAIN: 'preencher-novamente',
  LIMIT_REACHED: 'limite-alcancado',
  SUCCESS: 'sucesso',
  ERROR: 'erro',
}
