import React from 'react'

import EstateKey from '@/assets/estate_key.png'
import useBreakpoint from '@/hooks/useBreakpoint'
import { breakpoints } from '@/utils/constants/breakpoints'
import { Spacing } from '@inter/inter-ui/components/Spacing'
import { Text } from '@inter/inter-ui/components/Text'
import SuccessIcon from '@/assets/success.png'

import * as S from './styles'

const Success = () => {
  const { breakpoint } = useBreakpoint()
  const isLargeScreen = [breakpoints.LG].includes(breakpoint)

  return (
    <S.Container>
      {isLargeScreen && (
        <S.LeftBox>
          <S.Figure>
            <img src={EstateKey} alt="estate keys on hands" />
          </S.Figure>
        </S.LeftBox>
      )}
      <S.RightBox>
        <S.InfoContainer>
          <img src={SuccessIcon} alt="warning" width="64" />
          <Spacing mb="lg" />
          <Text variant="headline-h1" semiBold>
            Seu formulário foi enviado com sucesso
          </Text>
          <Spacing mb="xs" />
          <Text variant="body-3">
            Em breve, uma pessoa consultora do Inter entrará em contato com você.
          </Text>
          <Spacing my="md" />
        </S.InfoContainer>
      </S.RightBox>
    </S.Container>
  )
}

export default Success
