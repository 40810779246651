import { instance } from '../axios'

export type AttemptsType = {
  quantidade: number
  valido: boolean
}

type PayloadType = {
  codigoScci: string
  imovel: {
    cep: number
    endereco: string
    numero: number
    complemento: string
    bairro: string
    cidade: string
    estado: string
    tipo: string
    areaUtil: number
    quantidadeDormitorios: number
    quantidadeBanheiros: number
    quantidadeSuites: number
    quantidadeVagasGaragem: number
    anoConstrucao: number
    imovelEmReforma: string
    acabamento: string
    conservacao: string
    conservacaoCondominioPredio: string
  }
}

export const getAttempts = (codigoScci: string): Promise<AttemptsType> =>
  instance
    .get(`datazap/v1/imovel/valida-quantidade/${codigoScci}`)
    .then((result) => result.data)

export const saveData = (payload: PayloadType): Promise<void> =>
  instance.post('datazap/v1/imovel', payload)
