import React from 'react'

import { Flex } from '@inter/inter-ui/components/Flex'
import { Text } from '@inter/inter-ui/components/Text'
import { Spacing } from '@inter/inter-ui/components/Spacing'

import { RadioCardProps } from './types'
import * as S from './styles'

const RadioCard = ({ id, value, title, description }: RadioCardProps) => (
  <S.BorderRadio
    id={id}
    value={value}
    label={
      <Flex direction="column">
        <Text variant="headline-h3" semiBold>
          {title}
        </Text>
        <Spacing mb="xxs" />
        <Text variant="caption-1">{description}</Text>
      </Flex>
    }
  />
)

export default RadioCard
