import React, { createContext, useEffect, useState } from 'react'

export type EstateLocationType = {
  zipCode: string
  street: string
  number: string
  complement: string
  district: string
  state: string
  city: string
}

export type EstateFeaturesType = {
  estateType: string
  area: string
  dorms: number
  bathrooms: number
  suites: number
  garages: number
  buildingYear: number
  propertyCondition: string
  additionalData: string
}

export type EstateConditionType = 'BOM' | 'REGULAR' | 'RUIM'

export type FinishingStandardType = 'ALTO' | 'NORMAL' | 'BAIXO' | 'MINIMO'

export type CondominiumConditionType = 'ALTO' | 'NORMAL' | 'BAIXO' | 'MINIMO'

type ContextProps = {
  codigoScci: string
  setCodigoScci: React.Dispatch<React.SetStateAction<string>>
  estateLocation: EstateLocationType
  setEstateLocation: React.Dispatch<React.SetStateAction<EstateLocationType>>
  estateFeatures: EstateFeaturesType
  setEstateFeatures: React.Dispatch<React.SetStateAction<EstateFeaturesType>>
  estateCondition: EstateConditionType
  setEstateCondition: React.Dispatch<React.SetStateAction<EstateConditionType>>
  finishingStandard: FinishingStandardType
  setFinishingStandard: React.Dispatch<React.SetStateAction<FinishingStandardType>>
  condominiumCondition: CondominiumConditionType
  setCondominiumCondition: React.Dispatch<React.SetStateAction<CondominiumConditionType>>
  step: number
  setStep: React.Dispatch<React.SetStateAction<number>>
  isEditing: boolean
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>
}

const EstateContext = createContext<ContextProps>({} as ContextProps)

type Props = {
  children?: React.ReactNode
}

const storageEstateLocation = localStorage.getItem('estateLocation')
const storageEstateFeatures = localStorage.getItem('estateFeatures')
const storageStep = localStorage.getItem('step')
const storageIsEditing = localStorage.getItem('isEditing')
// const storageIsLogged = localStorage.getItem('isLogged')
// TODO: trechos comentados para quando a autenticação vier da staging

const EstateProvider: React.FC<Props> = ({ children }) => {
  const [codigoScci, setCodigoScci] = useState<string>(localStorage.getItem('codigoScci') || '')
  const [estateLocation, setEstateLocation] = useState<EstateLocationType>(
    storageEstateLocation && JSON.parse(storageEstateLocation),
  )
  const [estateFeatures, setEstateFeatures] = useState<EstateFeaturesType>(
    storageEstateFeatures && JSON.parse(storageEstateFeatures),
  )
  const [estateCondition, setEstateCondition] = useState<EstateConditionType>(
    (localStorage.getItem('estateCondition') || '') as EstateConditionType,
  )
  const [finishingStandard, setFinishingStandard] = useState<FinishingStandardType>(
    (localStorage.getItem('finishingStandard') || '') as FinishingStandardType,
  )
  const [condominiumCondition, setCondominiumCondition] = useState<CondominiumConditionType>(
    (localStorage.getItem('condominiumCondition') || '') as CondominiumConditionType,
  )
  const [step, setStep] = useState<number>((storageStep && JSON.parse(storageStep)) || 1)
  const [isEditing, setIsEditing] = useState<boolean>(
    (storageIsEditing && JSON.parse(storageIsEditing)) || false,
  )
  // const [isLogged, setIsLogged] = useState<boolean>(
  //   (storageIsLogged && JSON.parse(storageIsLogged)) || false,
  // )

  useEffect(() => {
    localStorage.setItem('estateLocation', JSON.stringify(estateLocation))
    localStorage.setItem('estateFeatures', JSON.stringify(estateFeatures))
    localStorage.setItem('estateCondition', estateCondition)
    localStorage.setItem('finishingStandard', finishingStandard)
    localStorage.setItem('condominiumCondition', condominiumCondition)
    localStorage.setItem('step', JSON.stringify(step))
    localStorage.setItem('isEditing', JSON.stringify(isEditing))
    // localStorage.setItem('isLogged', JSON.stringify(isLogged))
  }, [
    codigoScci,
    estateLocation,
    estateFeatures,
    estateCondition,
    finishingStandard,
    condominiumCondition,
    step,
    isEditing,
    // isLogged,
  ])

  useEffect(() => {
    // codigoScci !== localStorage.getItem('codigoScci') && setIsLogged(false)
    localStorage.setItem('codigoScci', codigoScci)
  }, [codigoScci])


  return (
    <EstateContext.Provider
      value={{
        codigoScci,
        setCodigoScci,
        estateLocation,
        setEstateLocation,
        estateFeatures,
        setEstateFeatures,
        estateCondition,
        setEstateCondition,
        finishingStandard,
        setFinishingStandard,
        condominiumCondition,
        setCondominiumCondition,
        step,
        setStep,
        isEditing,
        setIsEditing,
      }}
    >
      {children}
    </EstateContext.Provider>
  )
}

export { EstateProvider, EstateContext }
