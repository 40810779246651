import * as yup from 'yup'
import * as Validation from 'inter-frontend-lib-util-form/lib/validations'

export const validationSchema = yup
  .object()
  .shape({
    zipCode: yup
      .string()
      .test('is-cep', 'Informe um CEP válido', (value) => Validation.cep(value || ''))
      .required('Informe um CEP válido'),
    street: yup.string().required('Informe a rua'),
    number: yup.string().required('Informe o número'),
    complement: yup.string(),
    district: yup.string().required('Informe o bairro'),
    state: yup.string().required('Informe o estado'),
    city: yup.string().required('Informe a cidade'),
  })
  .required()
