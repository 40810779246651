import styled from 'styled-components'

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media (max-width: 768px) {
    align-items: flex-start;
    text-align: start;
  }

  .body-text {
    color: var(--typography500);
  }
`
