import styled from 'styled-components'

import { Radio } from '@inter/inter-ui/components/Radio'

export const BorderRadio = styled(Radio)`
  max-width: 460px;
  border: 1px solid var(--gray200);
  border-radius: 8px;
  padding: 16px 16px 16px 20px;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`
