import React, { useContext, useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'

import { FinishingStandardType, EstateContext } from '@/hooks/useEstateContext'
import { Spacing } from '@inter/inter-ui/components/Spacing'
import { Button } from '@inter/inter-ui/components/Button'
import { Text } from '@inter/inter-ui/components/Text'
import { Flex } from '@inter/inter-ui/components/Flex'
import { RadioGroup } from '@inter/inter-ui/components/RadioGroup'
import RadioCard from '@/components/RadioCard'
import routes from '@/routes/RoutesNames'

import options from './options.json'

const FinishingStandard = () => {
  const { finishingStandard, setFinishingStandard, setStep, isEditing } = useContext(EstateContext)
  const [selected, setSelected] = useState<FinishingStandardType>('' as FinishingStandardType)
  const navigate = useNavigate()

  useEffect(() => {
    setStep(4)
    setSelected(finishingStandard)
  }, [finishingStandard, setStep])

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setFinishingStandard(selected)
    !isEditing ? navigate(`/${routes.FORM_CONDOMINIUM}`) : navigate(`/${routes.FORM_REVIEW}`)
  }

  return (
    <form onSubmit={(event) => onSubmit(event)}>
      <Flex direction="column" justifyContent="space-between">
        <Text variant="headline-h3" semiBold>
          Qual o padrão de acabamento do apartamento?
        </Text>
        <Spacing mb="lg" />
        <RadioGroup
          name="options"
          value={selected}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setSelected(event.target.value as FinishingStandardType)
          }
          style={{ display: 'flex' }}
        >
          <Flex direction="column">
            {Object.keys(options).map((option, index) => (
              <>
                <RadioCard
                  id={`option-${index}`}
                  value={option}
                  title={options[option as FinishingStandardType].title}
                  description={options[option as FinishingStandardType].description}
                />
                <Spacing mb="xs" />
              </>
            ))}
          </Flex>
        </RadioGroup>

        <Spacing mb="sm" />
        <Button type="submit" fullWidth disabled={!selected}>
          Continuar
        </Button>
      </Flex>
    </form>
  )
}

export default FinishingStandard
