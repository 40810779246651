import React from 'react'

import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { EstateProvider } from '@/hooks/useEstateContext'
import { NotificationProvider } from '@inter/inter-ui/components/Notification'

import RoutesNames from './RoutesNames'
import Home from'@/pages/Home'
import EstateLocationPage from'@/pages/EstateLocation'
import EstateFeaturesPage from'@/pages/EstateFeatures'
import EstateConditionPage from'@/pages/EstateCondition'
import FinishingStandardPage from'@/pages/FinishingStandard'
import CondominiumConditionPage from'@/pages/CondominiumCondition'
import ReviewPage from'@/pages/Review'
import AttemptsPage from'@/pages/Attempts'
import LimitReachedPage from'@/pages/LimitReached'
import SuccessPage from'@/pages/Success'
import ErrorPage from'@/pages/Error'

const Router = () => (
  <EstateProvider>
    <NotificationProvider>
      <BrowserRouter basename="/">
        <Routes>
          <Route path={`${RoutesNames.HOME}/:codigoScci`} element={<Home />} />
          <Route path={`${RoutesNames.FORM_LOCATION}`} element={<EstateLocationPage />} />
          <Route path={`${RoutesNames.FORM_FEATURES}`} element={<EstateFeaturesPage />} />
          <Route path={`${RoutesNames.FORM_CONDITION}`} element={<EstateConditionPage />} />
          <Route
            path={`${RoutesNames.FORM_FINISHING_STANDARD}`}
            element={<FinishingStandardPage />}
          />
          <Route
            path={`${RoutesNames.FORM_CONDOMINIUM}`}
            element={<CondominiumConditionPage />}
          />
          <Route path={`${RoutesNames.FORM_REVIEW}`} element={<ReviewPage />} />
          <Route path={`${RoutesNames.FILL_AGAIN}`} element={<AttemptsPage />} />
          <Route path={`${RoutesNames.LIMIT_REACHED}`} element={<LimitReachedPage />} />
          <Route path={`${RoutesNames.SUCCESS}`} element={<SuccessPage />} />
          <Route path={`${RoutesNames.ERROR}`} element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
    </NotificationProvider>
  </EstateProvider>
)

export default Router
