import React from 'react'

import FinishingStandard from '@/components/EstateForm/FinishingStandard'
import FormContainer from '@/components/FormContainer'

const FinishingStandardPage = () => (
  <FormContainer>
    <FinishingStandard />
  </FormContainer>
)

export default FinishingStandardPage
