import React from 'react'

import { useNavigate } from 'react-router-dom'

import EstateKey from '@/assets/estate_key.png'
import useBreakpoint from '@/hooks/useBreakpoint'
import { breakpoints } from '@/utils/constants/breakpoints'
import { Spacing } from '@inter/inter-ui/components/Spacing'
import { Text } from '@inter/inter-ui/components/Text'
import { Button } from '@inter/inter-ui/components/Button'
import ErrorIcon from '@/assets/error.png'
import routes from '@/routes/RoutesNames'

import * as S from './styles'

const Error = () => {
  const { breakpoint } = useBreakpoint()
  const isLargeScreen = [breakpoints.LG].includes(breakpoint)
  const navigate = useNavigate()

  return (
    <S.Container>
      {isLargeScreen && (
        <S.LeftBox>
          <S.Figure>
            <img src={EstateKey} alt="estate keys on hands" />
          </S.Figure>
        </S.LeftBox>
      )}
      <S.RightBox>
        <S.InfoContainer>
          <img src={ErrorIcon} alt="warning" width="64" />
          <Spacing mb="lg" />
          <Text variant="headline-h1" semiBold>
            Erro no envio do formulário
          </Text>
          <Spacing mb="xs" />
          <Text variant="body-3">
            No momento, não foi possível enviar seu formulário. Por favor, tente novamente em alguns
            minutos.
          </Text>
          <Spacing my="md" />
          {!isLargeScreen && <Spacing my="xxl" />}
          <Button variant="primary" fullWidth onClick={() => navigate(`/${routes.FORM_REVIEW}`)}>
            Voltar
          </Button>
        </S.InfoContainer>
      </S.RightBox>
    </S.Container>
  )
}

export default Error
