import { instance } from '../axios'

export type cityType = {
  codigo: string
  descricao: string
}[]

export const getCitiesByState = (state: string): Promise<cityType> =>
  instance
    .get(`/ci-turbo/commons/v1/address/state/${state}/cities`)
    .then((result) => result.data.MUNICIPIO)
