import styled from 'styled-components'

import { Header } from '@inter/inter-ui/components/Header'

export const Stepper = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;

  @media (min-width: 1024px) {
    margin-bottom: 14px;
  }
`

export const StepperTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export const StepperBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`

export const CenteredHeader = styled(Header)`
  justify-content: center;
  border-bottom: 0;
  --header-current-step-background-color: var(--gray500);
  --header-step-lint-color: rgba(0,0,0,0);

  li {
    font-weight: 700;
  }
`
