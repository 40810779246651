import React, { useEffect } from 'react'

import { initTheme, Theme } from '@inter/inter-ui'

import * as S from './styles'

import Router from './routes'
import Header from './components/Header'
import '@inter/inter-ui/inter-ui.css'

const App = () => {
  useEffect(() => {
    initTheme(Theme.PF)
  }, [])

  return (
    <>
      <Header />
      <S.GlobalContainer>
        <Router />
      </S.GlobalContainer>
    </>
  )
}

export default App
