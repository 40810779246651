import React, { useContext, useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'

import { EstateContext } from '@/hooks/useEstateContext'
import { Spacing } from '@inter/inter-ui/components/Spacing'
import { Button } from '@inter/inter-ui/components/Button'
import { Text } from '@inter/inter-ui/components/Text'
import { Flex } from '@inter/inter-ui/components/Flex'
import { Checkbox } from '@inter/inter-ui/components/Checkbox'
import routes from '@/routes/RoutesNames'
import useBreakpoint from '@/hooks/useBreakpoint'
import { breakpoints } from '@/utils/constants/breakpoints'
import estateConditionOptions from '@/components/EstateForm/EstateCondition/options.json'
import finishingStandardOptions from '@/components/EstateForm/FinishingStandard/options.json'
import condominiumConditionOptions from '@/components/EstateForm/CondominiumCondition/options.json'
import Stepper from '@/components/Stepper'
import { saveData } from '@/services/datazap'
import { formatPayload } from '@/utils/payload'

import * as S from './styles'

const Info = ({ label, value }: { label?: string; value: string | number }) => (
  <Flex direction="column">
    <Text variant="body-3">{label}</Text>
    <Spacing mb="xxs" />
    <S.DarkText variant="body-3" bold>
      {value}
    </S.DarkText>
    <Spacing mb="xs" />
  </Flex>
)

const Review = () => {
  const {
    codigoScci,
    estateLocation,
    estateFeatures,
    estateCondition,
    finishingStandard,
    condominiumCondition,
    setStep,
    setIsEditing,
  } = useContext(EstateContext)
  const { zipCode, street, number, complement, district, state, city } = estateLocation
  const {
    estateType,
    area,
    dorms,
    bathrooms,
    suites,
    garages,
    buildingYear,
    propertyCondition,
    additionalData,
  } = estateFeatures
  const { breakpoint } = useBreakpoint()
  const isLargeScreen = [breakpoints.LG].includes(breakpoint)
  const [checked, setChecked] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  const handleSubmit = () => {
    const postData = async () => {
      setIsLoading(true)
      try {
        await saveData(
          formatPayload({
            codigoScci,
            estateLocation,
            estateFeatures,
            estateCondition,
            finishingStandard,
            condominiumCondition,
          }),
        )
        navigate(`/${routes.SUCCESS}`)
      } catch {
        navigate(`/${routes.ERROR}`)
      } finally {
        setIsLoading(false)
      }
    }
    postData()
  }

  const Title = ({ content, onEdit }: { content: string; onEdit: string }) => (
    <>
      <S.FullWidthFlex direction="row" alignItems="flex-start" justifyContent="flex-start">
        <S.DarkText variant="body-2" bold>
          {content}
        </S.DarkText>
        <Spacing mr="sm" />
        <Button
          type="button"
          variant="link"
          onClick={() => {
            setIsEditing(true)
            navigate(`/${onEdit}`)
          }}
          style={{ padding: 0 }}
        >
          Editar
        </Button>
      </S.FullWidthFlex>
      <Spacing mb="xs" />
    </>
  )

  useEffect(() => {
    setIsEditing(false)
    setStep(6)
  }, [setIsEditing, setStep])

  return (
    <Flex direction="column" alignItems="center">
      <Stepper />
      <div style={{ alignSelf: 'flex-start' }}>
        <Text variant="headline-h2" semiBold>
          Confira os dados preenchidos
        </Text>
        <Spacing mb="xs" />
        <Text variant="headline-h3">
          Se precisar, edite as informações que estiverem incorretas.
        </Text>
      </div>
      <Spacing mb="md" />
      <Flex direction={isLargeScreen ? 'row' : 'column'}>
        <S.ColumnSpacing mr="sm" mt="xs">
          <Flex direction="column">
            <Title content="Endereço atual do cliente" onEdit={routes.FORM_LOCATION} />
            <Info label="CEP" value={zipCode} />
            <Info label="Rua/Avenida" value={street} />
            <Flex direction="row">
              <Info label="Número" value={number} />
              <Info label="Complemento" value={complement} />
            </Flex>
            <Info label="Bairro" value={district} />
            <Flex direction="row">
              <Info label="Estado" value={state} />
              <Info label="Cidade" value={city} />
            </Flex>
          </Flex>
        </S.ColumnSpacing>
        <S.ColumnSpacing mr="sm" mt="xs">
          <Flex direction="column">
            <Title content="Características do Imóvel" onEdit={routes.FORM_FEATURES} />
            <Info label="Tipo do imóvel" value={estateType} />
            <Info label="Área útil" value={area} />
            <Info label="Quartos" value={dorms} />
            <Info label="Banheiros" value={bathrooms} />
            <Info label="Suíte" value={suites} />
            <Info label="Vagas de garagem" value={garages} />
            <Info label="Ano de construção" value={buildingYear} />
            <Info label="Imóvel está em reforma?" value={propertyCondition} />
            <Info label="Dados complementares" value={additionalData} />
          </Flex>
        </S.ColumnSpacing>
        <S.ColumnSpacing mr="sm" mt="xs">
          <Flex direction="column">
            <Title content="Estado de conservação do imóvel" onEdit={routes.FORM_CONDITION} />
            <Text variant="body-3">{estateConditionOptions[estateCondition].title}</Text>
            <Spacing mb="md" />
            <Title
              content="Padrão de acabamento do imóvel"
              onEdit={routes.FORM_FINISHING_STANDARD}
            />
            <Text variant="body-3">{finishingStandardOptions[finishingStandard].title}</Text>
            <Spacing mb="md" />
            <Title content="Estado de conservação condomínio" onEdit={routes.FORM_CONDOMINIUM} />
            <Text variant="body-3">{condominiumConditionOptions[condominiumCondition].title}</Text>
          </Flex>
        </S.ColumnSpacing>
      </Flex>
      <Spacing mb="lg" />
      <S.CheckboxContainer>
        <Checkbox
          id="responsability"
          label="Ao enviar, você se responsabiliza pela veracidade das informações preenchidas."
          checked={checked}
          reversed
          onChange={() => setChecked(!checked)}
        />
        <Spacing mb="xs" />
        <Button
          type="button"
          variant="primary"
          fullWidth
          disabled={!checked || isLoading}
          isLoading={isLoading}
          onClick={handleSubmit}
        >
          Enviar formulário
        </Button>
      </S.CheckboxContainer>
    </Flex>
  )
}

export default Review
