import styled from 'styled-components'

export const GlobalContainer = styled.div`
  padding: 92px;

  @media (max-width: 1023px) {
    padding: 32px 24px;
  }

  @media (min-width: 1440px) {
    padding: 92px 156px;
  }
`
