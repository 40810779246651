import { instance } from '../axios'

export type zipCodeType = {
  address: string
  cep: string
  city: string
  coCity: string
  neighborhood: string
  uf: string
}

export const getZipCode = (zipCode: string): Promise<zipCodeType> =>
  instance
    .get(`/ci-turbo/commons/v1/address/cep/${zipCode}?source=SCCI`)
    .then((result) => result.data)
