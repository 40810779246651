import * as yup from 'yup'

export const validationSchema = yup
  .object()
  .shape({
    estateType: yup.string().required('Informe o campo'),
    area: yup.string().required('Informe o campo'),
    dorms: yup.string().required('Informe o campo'),
    bathrooms: yup.string().required('Informe o campo'),
    suites: yup.string().required('Informe o campo'),
    garages: yup.string().required('Informe o campo'),
    buildingYear: yup.string().required('Informe o campo'),
    propertyCondition: yup.string().required('Informe o campo'),
    additionalData: yup.string(),
  })
  .required()
