import React from 'react'

import EstateLocation from '@/components/EstateForm/EstateLocation'
import FormContainer from '@/components/FormContainer'

const EstateLocationPage = () => (
  <FormContainer>
    <EstateLocation />
  </FormContainer>
)

export default EstateLocationPage
