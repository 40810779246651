import React, { useContext, useEffect, useState } from 'react'

import { useForm, useController } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import { MultiSelect, MultiSelectOption } from '@inter/inter-ui/components/MultiSelect'
import { Flex } from '@inter/inter-ui/components/Flex'
import { Button } from '@inter/inter-ui/components/Button'
import { Spacing } from '@inter/inter-ui/components/Spacing'
import { Text } from '@inter/inter-ui/components/Text'
import { Textarea } from '@inter/inter-ui/components/Textarea'
import { SelectNative } from '@inter/inter-ui/components/SelectNative'
import { Input } from '@inter/inter-ui/components/Input'
import { EstateFeaturesType, EstateContext } from '@/hooks/useEstateContext'
import PaintRoller from '@inter/icons/orangeds/MD/paint-roller'
import routes from '@/routes/RoutesNames'
import estateType from '@/utils/constants/estateType.json'
import propertyCondition from '@/utils/constants/propertyCondition.json'
import Info from '@inter/icons/orangeds/MD/info'
import Bathtub from '@inter/icons/orangeds/MD/bathtub'
import Building from '@inter/icons/orangeds/MD/building'
import Bed from '@inter/icons/orangeds/MD/bed'
import Help from '@/assets/help.png'
import useBreakpoint from '@/hooks/useBreakpoint'
import { breakpoints } from '@/utils/constants/breakpoints'

import { validationSchema } from './schema'
import * as S from './styles'
import Helper from '../Helper'

type HelperPropTypes = { setModalOpen: React.Dispatch<React.SetStateAction<boolean>> }

const fullWidthStyle = { style: { width: '100%' } }

const HelperButton = ({ setModalOpen }: HelperPropTypes) => (
  <Button
    variant="link"
    style={{ padding: '0', marginBottom: '-16px', zIndex: 2 }}
    onClick={() => {
      setModalOpen(true)
    }}
  >
    <img src={Help} alt="question mark" />
  </Button>
)

const EstateFeatures = () => {
  const { estateFeatures, setEstateFeatures, setStep, isEditing } = useContext(EstateContext)
  const [areaModal, setAreaModal] = useState(false)
  const [bathroomModal, setBathroomModal] = useState(false)
  const [yearModal, setYearModal] = useState(false)
  const [propertyConditionModal, setPropertyConditionModal] = useState(false)
  const [suiteModal, setSuiteModal] = useState(false)
  const [roomModal, setRoomModal] = useState(false)
  const navigate = useNavigate()
  const featureAmount = ['0', '1', '2', '3', '4', '5', '6']
  const { breakpoint } = useBreakpoint()
  const isLargeScreen = [breakpoints.LG].includes(breakpoint)

  const {
    handleSubmit,
    register,
    watch,
    control,
    formState: { isValid, errors },
  } = useForm<EstateFeaturesType>({
    defaultValues: {
      ...estateFeatures,
    },
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  })

  const { field: propertyConditionField } = useController({
    control,
    name: "propertyCondition"
  })

  useEffect(() => {
    setStep(2)
  }, [setStep])

  const handlePropertyConditionChange = (selectedPropertyConditions: readonly MultiSelectOption[]) =>
    propertyConditionField.onChange(selectedPropertyConditions.map(value => value.label).join(' '))

  const onSubmit = (values: EstateFeaturesType) => {
    setEstateFeatures(values)
    !isEditing ? navigate(`/${routes.FORM_CONDITION}`) : navigate(`/${routes.FORM_REVIEW}`)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} {...fullWidthStyle}>
      <Flex direction="column" justifyContent="space-between">
        <Text variant="headline-h3" semiBold>
          Características do Imóvel
        </Text>
        <Spacing mb="xs" />
        <SelectNative
          label="Tipo do Imóvel"
          error={Boolean(errors.estateType)}
          infoText={errors.estateType?.message as unknown as string}
          options={estateType.map((value) => ({ value, text: value }))}
          {...register('estateType')}
          {...fullWidthStyle}
        />
        <Flex direction={isLargeScreen ? 'row' : 'column'} alignItems="flex-end">
          <Flex
            direction="column"
            alignItems="flex-end"
            style={{ marginRight: isLargeScreen ? '16px' : '0px' }}
          >
            <HelperButton setModalOpen={setAreaModal} />
            <Input
              id="area"
              type="number"
              label="Área útil (m²)"
              error={Boolean(errors.area)}
              infoText={errors.area?.message as unknown as string}
              {...register('area')}
              {...fullWidthStyle}
            />
          </Flex>
          <Flex
            direction="column"
            alignItems="flex-end"
          >
            <HelperButton setModalOpen={setRoomModal} />
            <SelectNative
              label="Quartos"
              error={Boolean(errors.dorms)}
              infoText={errors.dorms?.message as unknown as string}
              options={featureAmount.map((value) => ({ value, text: value }))}
              {...register('dorms')}
              {...fullWidthStyle}
            />
          </Flex>
        </Flex>
        <Flex direction="row">
          <S.InputLeft>
            <Flex direction="column" alignItems="flex-end">
              <HelperButton setModalOpen={setBathroomModal} />
              <SelectNative
                label="Banheiros"
                error={Boolean(errors.bathrooms)}
                infoText={errors.bathrooms?.message as unknown as string}
                options={featureAmount.map((value) => ({ value, text: value }))}
                {...register('bathrooms')}
                {...fullWidthStyle}
              />
            </Flex>
          </S.InputLeft>
          <S.InputRight>
            <Flex direction="column" alignItems="flex-end">
              <HelperButton setModalOpen={setSuiteModal} />
              <SelectNative
                label="Suítes"
                error={Boolean(errors.suites)}
                infoText={errors.suites?.message as unknown as string}
                options={featureAmount.map((value) => ({ value, text: value }))}
                {...register('suites')}
                {...fullWidthStyle}
              />
            </Flex>
          </S.InputRight>
        </Flex>
        <Flex direction={isLargeScreen ? 'row' : 'column'} alignItems="flex-end">
          <SelectNative
            label="Vagas de garagem"
            error={Boolean(errors.garages)}
            infoText={errors.garages?.message as unknown as string}
            options={featureAmount.map((value) => ({ value, text: value }))}
            {...register('garages')}
            style={{ marginRight: isLargeScreen ? '16px' : '0px', width: '100%' }}
          />
          <Flex direction="column" alignItems="flex-end">
            <HelperButton setModalOpen={setYearModal} />
            <Input
              id="buildingYear"
              type="text"
              label="Ano de construção"
              mask="9999"
              error={Boolean(errors.buildingYear)}
              infoText={errors.buildingYear?.message as unknown as string}
              {...register('buildingYear')}
              {...fullWidthStyle}
            />
          </Flex>
        </Flex>
        <Flex direction="column" alignItems="flex-end">
          <HelperButton setModalOpen={setPropertyConditionModal} />
          <MultiSelect
            id='propertyCondition'
            label="Em qual condição o imóvel se enquadra?"
            options={propertyCondition.map((value) => ({ value, label: value }))}
            name={propertyConditionField.name}
            onChange={handlePropertyConditionChange}
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                alignItems: 'center',
                backgroundColor: 'var(--gray100)',
                borderColor: 'transparent',
                borderRadius: 'var(--radiiMd)',
                borderStyle: 'solid',
                borderWidth: '1px',
                boxShadow: 'none',
                cursor: 'default',
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                minHeight: '48px;',
                position: 'relative',
                transition: 'all 100ms ease 0s',
                boxSizing: 'border-box',
                color: 'var(--highlight-color)',
                outline: '0px !important',
                ':hover': {
                  borderColor: 'transparent',
                },
              }),
            }}
            error={Boolean(errors.propertyCondition)}
            infoText={errors.propertyCondition?.message as unknown as string}
          />
        </Flex>
        <Spacing mb="xs"/>
        <Textarea
          id="additionalData"
          label="Dados complementares"
          value={watch('additionalData')}
          maxLength={500}
          isCounter
          {...register('additionalData')}
        />
        <Spacing mb="xxs" mt="xxxs" />
        <Button type="submit" fullWidth disabled={!isValid}>
          Continuar
        </Button>
      </Flex>
      <Helper
        isOpen={areaModal}
        handleClick={() => setAreaModal(false)}
        buttonText="Entendi"
        title="Área útil"
        content="É medida do espaço interno do imóvel, com exceção das paredes, medidas à partir do piso. Neste cálculo, não entram garagens, varandas e áreas de lazer cobertas."
        iconModal={<Info height={46} width={46} color="#FF7A00" />}
        onClose={() => setAreaModal(false)}
      />
      <Helper
        isOpen={bathroomModal}
        handleClick={() => setBathroomModal(false)}
        buttonText="Entendi"
        title="Banheiros"
        content="Informe o número de banheiros em seu imóvel. Lembre-se de incluir as suítes e, se houver, o lavabo."
        iconModal={<Bathtub height={46} width={46} color="#FF7A00" />}
        onClose={() => setBathroomModal(false)}
      />
      <Helper
        isOpen={yearModal}
        handleClick={() => setYearModal(false)}
        buttonText="Entendi"
        title="Ano de construção"
        content="Se o ano exato de construção não for do seu conhecimento, preencha com o ano aproximado ou procure na Matrícula do Imóvel."
        iconModal={<Building height={46} width={46} color="#FF7A00" />}
        onClose={() => setYearModal(false)}
      />
      <Helper
        isOpen={suiteModal}
        handleClick={() => setSuiteModal(false)}
        buttonText="Entendi"
        title="Suítes"
        content="Dos quartos declarados acima, quantos são suítes?"
        iconModal={<Bed height={46} width={46} color="#FF7A00" />}
        onClose={() => setSuiteModal(false)}
      />
      <Helper
        isOpen={roomModal}
        handleClick={() => setRoomModal(false)}
        buttonText="Entendi"
        title="Quartos"
        content="A dependência ou quarto de serviço não deve ser incluído no número total de quartos."
        iconModal={<Bed height={46} width={46} color="#FF7A00" />}
        onClose={() => setRoomModal(false)}
      />
      <Helper
        isOpen={propertyConditionModal}
        handleClick={() => setPropertyConditionModal(false)}
        buttonText="Entendi"
        title="Condições que impedem a Habitabilidade"
        content="Caso se aplique, selecione mais de uma opção."
        iconModal={<PaintRoller height={46} width={46} color="#FF7A00" />}
        onClose={() => setPropertyConditionModal(false)}
      />

    </form>
  )
}

export default EstateFeatures
