import React, { ReactNode } from 'react'

import { Button } from '@inter/inter-ui/components/Button'
import { BaseModal } from '@inter/inter-ui/components/Modal/BaseModal'
import { Text } from '@inter/inter-ui/components/Text'
import { Flex } from '@inter/inter-ui/components/Flex'
import { BottomSheet } from '@inter/inter-ui/components/BottomSheet'
import { Spacing } from '@inter/inter-ui/components/Spacing'
import { breakpoints } from '@/utils/constants/breakpoints'
import useBreakpoint from '@/hooks/useBreakpoint'

import * as S from './styles'

type HelperType = {
  isOpen: boolean
  handleClick?: () => void
  onClose: () => void
  buttonText?: string
  title: ReactNode
  content: string
  iconModal: ReactNode
  buttonIcon?: ReactNode
  customButton?: ReactNode
}

const Helper = ({
  isOpen,
  handleClick,
  buttonText,
  title,
  content,
  iconModal,
  onClose,
  buttonIcon,
  customButton,
}: HelperType) => {
  const { breakpoint } = useBreakpoint()

  const isMobile = [breakpoints.XS, breakpoints.SM].includes(breakpoint)

  if (isMobile && isOpen) {
    return (
      <BottomSheet onClose={onClose}>
        <Flex justifyContent="space-between" direction="column">
          <Text variant="headline-h3" semiBold>
            {title}
          </Text>
          <Spacing my="xs">
            <Text variant="body-3">
              <p dangerouslySetInnerHTML={{ __html: content }} />
            </Text>
          </Spacing>
          {customButton || (
            <Button onClick={handleClick} fullWidth {...(buttonIcon ? { icon: buttonIcon } : {})}>
              {buttonText}
            </Button>
          )}
        </Flex>
      </BottomSheet>
    )
  }

  return (
    <BaseModal
      visible={isOpen}
      onClose={onClose}
      onClickOutside={onClose}
      width={552}
      style={isMobile ? { padding: '24px' } : { padding: '48px 96px' }}
      bottomFade={false}
    >
      <S.ModalContainer>
        {iconModal}
        <Spacing mb="xs" />
        <Text variant={isMobile ? 'headline-h3' : 'headline-h1'} semiBold>
          {title}
        </Text>
        <Spacing mb="xs" />
        <Text className="body-text" variant="body-3">
          <p dangerouslySetInnerHTML={{ __html: content }} />
        </Text>
        <Spacing mb="sm" />
        {customButton || (
          <Button fullWidth onClick={handleClick} {...(buttonIcon ? { icon: buttonIcon } : {})}>
            {buttonText}
          </Button>
        )}
      </S.ModalContainer>
    </BaseModal>
  )
}

export default Helper
