import React from 'react'

import { Text } from '@inter/inter-ui/components/Text'
import Error from '@/assets/error.png'
import { Spacing } from '@inter/inter-ui/components/Spacing'
import useBreakpoint from '@/hooks/useBreakpoint'
import { breakpoints } from '@/utils/constants/breakpoints'

import * as S from './styles'

const LimitReached = () => {
  const { breakpoint } = useBreakpoint()
  const isLargeScreen = [breakpoints.LG].includes(breakpoint)

  return (
    <S.Container direction="column" alignItems="center">
      {!isLargeScreen && <Spacing mt="xxxl" />}
      <img src={Error} alt="warning" width="64" />
      <Spacing mb="lg" />
      <Text variant="headline-h2" semiBold>
        Limite alcançado
      </Text>
      <Spacing mb="xs" />
      <Text variant="body-3">
        Você já preencheu o formulário anteriormente e excedeu o limite de envios permitido.
      </Text>
      <Spacing my="xxl" />
    </S.Container>
  )
}

export default LimitReached
