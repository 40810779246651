const ucwords = (text: string) => {
  if (text) {
    const words = text.toLowerCase().split(' ')
    for (let a = 0; a < words.length; a += 1) {
      const w = words[a]
      if (w) words[a] = w[0].toUpperCase() + w.slice(1)
      else words[a] = ''
    }
    return words.join(' ')
  }
  return text
}

export { ucwords }
