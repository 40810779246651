import { useNotification } from '@inter/inter-ui/components/Notification'

export const useHandleErrorNotification = () => {
  const { showNotification } = useNotification()

  const handleErrorNotification = () =>
    showNotification({
      title: 'Algo deu errado.',
      content: 'Tente novamente mais tarde.',
      type: 'ERROR',
    })

  return { handleErrorNotification }
}
