import React from 'react'

import CondominiumCondition from '@/components/EstateForm/CondominiumCondition'
import FormContainer from '@/components/FormContainer'

const CondominiumConditionPage = () => (
  <FormContainer>
    <CondominiumCondition />
  </FormContainer>
)

export default CondominiumConditionPage
