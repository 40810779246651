import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1 1 0;
`

export const LeftBox = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`

export const InfoContainer = styled.article`
  max-width: 456px;
`

export const RightBox = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  text-align: center;
`

export const Figure = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
