import React from 'react'

import { useNavigate } from 'react-router-dom'

import { Text } from '@inter/inter-ui/components/Text'
import Warning from '@/assets/warning.png'
import { Spacing } from '@inter/inter-ui/components/Spacing'
import { Button } from '@inter/inter-ui/components/Button'
import useBreakpoint from '@/hooks/useBreakpoint'
import { breakpoints } from '@/utils/constants/breakpoints'
import routes from '@/routes/RoutesNames'

import * as S from './styles'

const Attempts = () => {
  const { breakpoint } = useBreakpoint()
  const isLargeScreen = [breakpoints.LG].includes(breakpoint)
  const navigate = useNavigate()

  return (
    <S.Container direction="column" alignItems="center">
      {!isLargeScreen && <Spacing mt="xxxl" />}
      <img src={Warning} alt="warning" width="64" />
      <Spacing mb="lg" />
      <Text variant="headline-h2" semiBold>
        Você já preencheu este formulário anteriormente
      </Text>
      <Spacing mb="xs" />
      <Text variant="body-3">
        Vimos que você já respondeu e enviou esse formulário. Você quer preencher novamente?
      </Text>
      <Spacing my="xxl" />
      <Button variant="primary" fullWidth onClick={() => navigate(`/${routes.FORM_LOCATION}`)}>
        Quero preencher novamente
      </Button>
    </S.Container>
  )
}

export default Attempts
