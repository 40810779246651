import React, { Suspense } from 'react'

import ReactDOM from 'react-dom/client'

import App from './App'

import '@inter/inter-ui/inter-ui.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <Suspense fallback="Loading...">
    <App />
  </Suspense>,
)
