import React, { useContext } from 'react'

import { EstateContext } from '@/hooks/useEstateContext'
import { Spacing } from '@inter/inter-ui/components/Spacing'

import * as S from './styles'
import { StepperProps } from './types'

const limitStep = 6

const Stepper = ({ className }: StepperProps) => {
  const { step } = useContext(EstateContext)

  return (
    <S.Stepper className={className}>
      <S.CenteredHeader currentStep={Number(step)} steps={limitStep} />
      <Spacing mb="xxs" />
    </S.Stepper>
  )
}

export default Stepper
