import React from 'react'

import { Flex } from '@inter/inter-ui/components/Flex'
import { Text } from '@inter/inter-ui/components/Text'
import interLogo from '@/assets/inter_logo.png'
import useBreakpoint from '@/hooks/useBreakpoint'
import { breakpoints } from '@/utils/constants/breakpoints'

import * as S from './styles'

const Header = () => {
  const { breakpoint } = useBreakpoint()
  const isLargeScreen = [breakpoints.LG].includes(breakpoint)

  return (
    <S.Header>
      <Flex direction="row" justifyContent="space-between" alignItems="center">
        <img src={interLogo} alt="inter logo" width={isLargeScreen ? 104 : 68} />
        <Text variant={isLargeScreen ? 'headline-h1' : 'headline-h3'} semiBold>
          Dados do Imóvel
        </Text>
      </Flex>
    </S.Header>
  )
}

export default Header
