export function applyCEPMask(value: string) {
  if (value) {
    return String(value)
      .replace(/\D/g, '')
      .replace(/^(\d{2})(\d{3})(\d{3})+?$/, '$1.$2-$3')
  }

  return ''
}
export const removeMask = (value: string) =>
  typeof value === 'string' ? value.replace(/\D/g, '') : value
