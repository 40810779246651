import styled from 'styled-components'

export const InputLeft = styled.div`
  width: 100%;
  margin-right: 8px;
`

export const InputRight = styled.div`
  width: 100%;
  margin-left: 8px;
`
