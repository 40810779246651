import React, { useContext, useEffect, useState } from 'react'

import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import { Flex } from '@inter/inter-ui/components/Flex'
import { Button } from '@inter/inter-ui/components/Button'
import { Spacing } from '@inter/inter-ui/components/Spacing'
import { Text } from '@inter/inter-ui/components/Text'
import { SelectNative } from '@inter/inter-ui/components/SelectNative'
import { AutoComplete } from '@inter/inter-ui/components/AutoComplete'
import { Input } from '@inter/inter-ui/components/Input'
import { EstateLocationType, EstateContext } from '@/hooks/useEstateContext'
import { getZipCode } from '@/services/zipCode'
import ufs from '@/utils/constants/ufs.json'
import { cityType, getCitiesByState } from '@/services/cities'
import { ucwords } from '@/utils/words'
import { applyCEPMask, removeMask } from '@/utils/Masks'
import routes from '@/routes/RoutesNames'

import { validationSchema } from './schema'
import * as S from './styles'

const isCepFound = ({ city, uf }: { city: string; uf: string }) => city && uf

const normalizeCities = (cities = [] as cityType) =>
  cities.map(({ descricao }) => ucwords(descricao))

const EstateLocation = () => {
  const { estateLocation, setEstateLocation, setStep, isEditing } = useContext(EstateContext)
  const [isZipCodeValid, setIsZipCodeValid] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [cities, setCities] = useState<string[]>([])
  const navigate = useNavigate()

  const {
    handleSubmit,
    register,
    formState: { isValid, isValidating, errors },
    watch,
    setValue,
    control,
    getValues,
    trigger,
  } = useForm({
    defaultValues: {
      ...estateLocation,
    },
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  })
  const watchZipCode = watch('zipCode')
  const watchState = watch('state')
  const fullWidthStyle = { style: { width: '100%' } }

  useEffect(() => {
    setStep(1)
  }, [setStep])

  useEffect(() => {
    async function fetchCep() {
      if (!errors.zipCode && watchZipCode) {
        setIsLoading(true)

        try {
          const data = await getZipCode(removeMask(watchZipCode))

          if (isCepFound(data)) {
            
            setIsZipCodeValid(!!data.address && data.address !== ' ' && !!data.neighborhood)
          } else {
            setIsZipCodeValid(false)
          }
          const { address, neighborhood, uf, city } = data
          setValue('street', address)
          setValue('district', neighborhood)
          setValue('state', uf)
          
          city ? setValue('city', city) : setValue('city', '')
        } catch {
          setIsZipCodeValid(false)
        } finally {
          setIsLoading(false)
        }
      }
    }

    fetchCep()
  }, [watchZipCode, setValue, errors.zipCode])

  useEffect(() => {
    const searchCityByState = async () => {
      setCities([])

      if (watchState) {
        setIsLoading(true)
        if (!isZipCodeValid) {
          setValue('city', '')
        }

        try {
          const data = await getCitiesByState(watchState)
          const normalizedCities = normalizeCities(data)

          setCities(normalizedCities)

          !normalizedCities.some((city) => city === ucwords(getValues('city'))) && setValue('city', '')
          trigger('city')

        } finally {
          setIsLoading(false)
        }
      }
    }

    searchCityByState()
  }, [watchState, setValue, isZipCodeValid])

  const onSubmit = (values: EstateLocationType) => {
    setEstateLocation(values)
    !isEditing ? navigate(`/${routes.FORM_FEATURES}`) : navigate(`/${routes.FORM_REVIEW}`)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} {...fullWidthStyle}>
      <Flex direction="column" justifyContent="space-between">
        <Text variant="headline-h3" semiBold>
          Localização do Imóvel
        </Text>
        <Spacing mb="xs" />
        <Input
          id="zipCode"
          type="tel"
          mask="99.999-999"
          label="CEP"
          value={applyCEPMask(getValues('zipCode'))}
          error={Boolean(errors.zipCode)}
          infoText={errors.zipCode?.message as unknown as string}
          {...register('zipCode')}
          {...fullWidthStyle}
        />
        <Input
          id="street"
          type="text"
          label="Rua/Avenida"
          error={Boolean(errors.street)}
          infoText={errors.street?.message as unknown as string}
          {...register('street')}
          {...fullWidthStyle}
        />
        <Flex direction="row">
          <S.InputLeft>
            <Input
              id="number"
              type="number"
              label="Número"
              error={Boolean(errors.number)}
              infoText={errors.number?.message as unknown as string}
              {...register('number')}
              {...fullWidthStyle}
            />
          </S.InputLeft>
          <S.InputRight>
            <Input
              id="complement"
              type="text"
              label="Complemento"
              error={Boolean(errors.complement)}
              infoText={errors.complement?.message as unknown as string}
              {...register('complement')}
              {...fullWidthStyle}
            />
          </S.InputRight>
        </Flex>
        <Input
          id="district"
          type="text"
          label="Bairro"
          error={Boolean(errors.district)}
          infoText={errors.district?.message as unknown as string}
          {...register('district')}
          {...fullWidthStyle}
        />
        <Flex direction="row">
          <S.InputLeft>
            <SelectNative
              id="state"
              label="Estado"
              error={Boolean(errors.state)}
              infoText={errors.state?.message as unknown as string}
              options={Object.entries(ufs).map(([key, value]) => ({ value: key, text: value }))}
              {...register('state')}
              {...fullWidthStyle}
            />
          </S.InputLeft>
          <S.InputRight>
            <Controller
              control={control}
              {...register('city')}
              render={({ field: { value, onChange } }) => (
                <AutoComplete
                  id="city"
                  label="Cidade"
                  placeholder="Selecione"
                  value={value}
                  onChange={(v: string) => onChange(v)}
                  dataList={cities}
                  error={Boolean(errors.city)}
                  infoText={errors.city?.message as unknown as string}
                  {...fullWidthStyle}
                />
              )}
            />
          </S.InputRight>
        </Flex>
        <Spacing mb="xxs" mt="xxxs" />
        <Button
          type="submit"
          fullWidth
          disabled={!isValid || isValidating || isLoading}
          isLoading={isValidating || isLoading}
        >
          Continuar
        </Button>
      </Flex>
    </form>
  )
}

export default EstateLocation
