import { useState, useEffect, useRef } from 'react'

const getDeviceConfig = (width: number) => {
  if (width < 320) {
    return 'xs'
  }
  if (width >= 320 && width < 769) {
    return 'sm'
  }
  if (width >= 769 && width < 1024) {
    return 'md'
  }
  return 'lg'
}

const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState(() => getDeviceConfig(window.innerWidth))
  const throttlePause = useRef(false)

  useEffect(() => {
    const calcInnerWidth = () => {
      if (throttlePause.current) return

      throttlePause.current = true

      setTimeout(() => {
        setBreakpoint(getDeviceConfig(window.innerWidth))
        throttlePause.current = false
      }, 300)
    }
    window.addEventListener('resize', calcInnerWidth)
    return () => window.removeEventListener('resize', calcInnerWidth)
  }, [])

  return { breakpoint }
}
export default useBreakpoint
