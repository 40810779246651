import React from 'react'

import EstateCondition from '@/components/EstateForm/EstateCondition'
import FormContainer from '@/components/FormContainer'

const EstateConditionPage = () => (
  <FormContainer>
    <EstateCondition />
  </FormContainer>
)

export default EstateConditionPage
